.modal-reserva {
    position: fixed;
    right: -100%;
    top: 0px;
    width: 100%;
    height: 100vh;
    transition: all 0.3s;
    z-index: 1000;
    @include lg() {
        position: fixed;
        right: -425px;
        top: 50px;
        width: 425px;
        height: calc( 100% - 80px);
        transition: all 0.3s;
        z-index: 1000;
    }
    &.active {
        right: 0px;
    }
    .modal-wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        padding-top: 30px;
        @include lg() {
            top: 30px;
            padding-top: 0px;
        }
        .modal-title {
            position: relative;
            font-size: 18px;
            //padding: 35px 62px 30px 42px;
            font-family: $archivo_bold;
            color: var(--colorPrincipal);
            .btn-title {
                cursor: pointer;
                height: 74px;
                padding-left: 42px;
                padding-top: 35px;
                width: calc( 100% - 148px);
                h3 {
                    font-size: 20px;
                }
            }
            .btn-alerts {
                align-items: center;
                cursor: pointer;
                display: flex;
                height: 74px;
                justify-content: center;
                position: absolute;
                right: 74px;
                top: 0px;
                width: 74px;
                svg {
                    height: 19px;
                    width: 19px;
                    g {
                        stroke-width: 2px;
                    }
                }
            }
            .btn-close {
                position: absolute;
                right: 0px;
                top: 0px;
                width: 74px;
                height: 74px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg {
                    height: 19px;
                    width: 19px;
                    g {
                        stroke-width: 2px;
                    }
                }
                span {
                    display: inline-block;
                    width: 14px;
                    height: 14px;
                    background-image: url('../../assets/img/sprite.png');
                    background-position: -88px -420px;
                }
            }
        }
        .modal-info-body {
            color: $white;
            height: calc( 100vh - 180px );
            margin-top: 17px;
            overflow: auto;
            padding: 0px 20px 0px 20px;
            position: relative;
            @include lg(){
                height: calc( 100vh - 173px );
                overflow: auto;
                padding: 20px 62px 25px 35px;
            }
            .modal-info-row {
                color: $white;
                display: flex;
                font-family: $archivo_regular;
                font-size: 16px;
                margin-bottom: 18px;
                position: relative;
                .icon {
                    text-align: center;
                    width: 30px;
                }
                p {
                    margin-left: 10px;
                    position: relative;
                    top: 1px;
                    &.info {
                        color: var(--colorSecundario);
                        font-family: $archivo_bold;
                    }
                }
            }
            footer {
                bottom: 25px;
                font-family: $archivo_semibold;
                font-size: 16px;
                margin-left: 7px;
                position: absolute;
                width: calc(100% - 97px);
                .multiple {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    gap: 8px;
                    .info-btn {
                        align-items: center;
                        background-color: var(--colorPrincipal);
                        color: $white;
                        cursor: pointer;
                        display: flex;
                        flex: 1 1;
                        height: 43px;
                        justify-content: center;
                        &.pay {
                            background-color: var(--color3);
                            svg {
                                path {
                                    fill: $black;
                                }
                            }
                        }
                    }
                }
                .single {
                    .no-date {
                        border: 1px solid $white;
                        cursor: pointer;
                        padding: 15px 0px;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
        .modal-body {
            padding: 0px 20px 0px 20px;
            height: calc( 100vh - 180px );
            overflow: auto;
            margin-top: 17px;
            @include lg(){
                padding: 0px 62px 40px 46px;
                height: calc( 100vh - 185px );
                overflow: auto;
            }
			.modal-info-row {
                color: $white;
                display: flex;
                font-family: $archivo_regular;
                font-size: 16px;
                margin-bottom: 18px;
                position: relative;
                .icon {
                    text-align: center;
                    width: 30px;
                }
                p {
                    
                    position: relative;
                    top: 1px;
                    &.info {
                        color: var(--colorSecundario);
                        font-family: $archivo_bold;
                    }
                }
            }
            .brb-text {
                color: $white;
                font-family: $archivo_regular;
                font-size: 16px;
                padding: 10px 0px;
                border-bottom: 1px solid $azul_borde_select;
                margin-bottom: 24px;

                a {
                    color: $white;
                    text-decoration: none;
                }
            }
            .brb-select {
                position: relative;
                .brb-select-container {
                    color: $modal_reservar_text;
                    font-size: 19px;
                    padding: 10px 0px 3px;
                    border-bottom: 1px solid $azul_borde_select;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 24px;
                    z-index: 1;
                    pointer-events: unset;
                    @include lg(){
                        padding: 10px 0px;
                        font-size: 20px;
                    }
                    p {
                        font-family: $archivo_regular;
                        font-size: 16px;
                        color: $azul_borde_select;
                    }
                    input {
                        color: $azul_borde_select;
                        border: none;
                        background-color: transparent;
                        font-family: $archivo_regular;
                        font-size: 16px;
                        &::placeholder {
                            color: #707070;
                        }
                    }
    
                    .right-zone {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        min-width: 20px;
                        p {
                            margin-right: 40px;
                            display: none;
                            @include lg(){
                                display: none;
                            }
                        }
                        span {
                            margin-right: 12px;
                            width: 7px;
                            height: 5px;
                            background-image: url('../../assets/img/sprite.png');
                            background-position: -222px -8px;
                            @include lg(){
                                margin-right: 5px;
                                width: 7px;
                                height: 5px;
                                background-position: -224px -419px;
                            }
                        }
                    } 
    
                    &.active {
                        &.visible {
                            .right-zone {
                                span {
                                    background-position: -215px -419px;
                                    @include lg(){
                                        background-position: -215px -419px;
                                    }
                                }
                            }
                        }
                    }
    
                    &.visible {
                        color: $white;
                        border-color: $azul_borde_select;
                        pointer-events: initial;
                        cursor: pointer;
                        input {
                            color: $white;
                            &::placeholder {
                                color: $azul_borde_select;
                            }
                        }
                        .right-zone {
                            span {
                                background-image: url('../../assets/img/sprite.png');
                                background-position: -215px -419px;
                                @include lg(){
                                    background-position: -215px -419px;
                                }
                            }
                        }
                    }
                }
                .brb-select-options {
                    position: absolute;
                    top: calc(100% - 2px);
                    left: 0px;
                    width: calc(100% - 2px);
                    background-color: $azul_select;
                    height: 0px;
                    overflow: auto;
                    z-index: 3;
                    transition: height 0.3s;
                    &.active {
                        top: 100%;
                        max-height: 491px;
                        height: auto;
                        @include lg(){
                            top: calc(100% - 4px);
                            max-height: 247px;
                        }
                        &.select-date {
                            max-height: unset;
                        }
                        &.select-time {
                            max-height: 275px;
                        }
                        &.long {
                            height: 564px;
                            max-height: 564px;
                        }
                    }
                    
                    .brb-select-option {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 16px 15px;
                        font-size: 20px;
                        border-bottom: 1px solid $azul_borde_select;
                        background-color: $azul_select;
                        cursor: pointer;
                        color: $gris_oscuro;
                        @include lg(){
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            font-size: 18px;
                        }
                        &:last-of-type {
                            border-bottom: none;
                        }
                        p {
                            font-family: $archivo_semibold; 
                            padding-bottom: 8px;
                            @include lg(){
                                padding-bottom: 0px;
                                font-size: 14px;
                            }
                        }
                        label.container {
                            font-family: $archivo_semibold;
                            font-size: 14px;
                        }
                        .right-container {
                            overflow: hidden;
                            padding-top: 6px;
                            @include lg(){
                                padding-top: 0px;
                                display: none;
                            }
                            .price {
                                font-family: $archivo_semibold; 
                                font-size: 18px;
                            }
                            .horario, .adress {
                                font-family: $archivo_semibold; 
                                font-size: 18px;
                                @include lg(){
                                    font-size: 16px; 
                                }
                            }
                            .horario {
                                font-size: 17px;
                                @include lg(){
                                    font-size: 16px; 
                                }
                            }
                        }
                        .ylm-select-checkbox {
                            position: relative;
                            padding-left: 30px;
                            font-family: $archivo_bold;
                            font-size: 14px;
                            padding-top: 2px;
                            cursor: pointer;
                            .checkmark {
                                border-color: var(--colorPrincipal);
                                background-color: transparent;
                            }
                            &.checked {
                                .checkmark {
                                    background-color: var(--colorPrincipal);
                                    &::after {
                                        display: block;
                                        left: 4px;
                                        top: 0px;
                                        width: 4px;
                                        height: 9px;
                                        border: solid $white;
                                        border-width: 0px 2px 2px 0px;
                                        -webkit-transform: rotate(45deg);
                                        -ms-transform: rotate(45deg);
                                        transform: rotate(45deg);
                                    }
                                }
                            }
                        }
                    }

                    .brb-select-load {
                        align-items: center;
                        display: flex;
                        font-family: $archivo_bold;
                        justify-content: center;
                        padding: 16px 15px;
                    }
    
                    &.select-usuario {
                        overflow: hidden;
                        .scroll-zone {
                            height: 192px;
                            overflow: auto;
                        }
                        .add-user-form {
                            .active-button {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 0px;
                                background-color: var(--colorPrincipal);
                                color: $white;
                                font-family: $archivo_semibold;
                                font-size: 14px;
                                text-transform: uppercase;
                                cursor: pointer;
                                transition: height 0.3s;
                                overflow: hidden;
                                &.active {
                                    height: 58px;
                                }
                            }
                        }
                        .new-user-form {
                            form {
                                background-color: var(--colorPrincipal);
                                padding: 30px 20px 20px;
                                .form-item {
                                    position: relative;
                                    color: #E3E1D8;
                                    margin-top: 29px;
                                    &:first-of-type {
                                        margin-top: 0px;
                                    }
                                    input {
                                        border: none;
                                        background-color: transparent;
                                        color: #E3E1D8;
                                        border-bottom: 1px solid $azul_borde_select;
                                        width: 100%;
                                        font-size: 14px;
                                        padding: 0px;
                                        padding-bottom: 5px;
                                        &::placeholder {
                                            color: #E3E1D8;
                                        }
                                    }
                                    .error-text {
                                        position: absolute;
                                        font-size: 12px;
                                        bottom: -15px;
                                        height: 0px;
                                        overflow: hidden;
                                        color: #AF9872;
                                        background-color: #E3E1D8;;
                                        transition: height 0.3s;
                                        &.active {
                                            padding: 2px;
                                            height: 16px;
                                        }
                                    }
                                }
                                .form-messages {
                                    display: none;
                                    &.active {
                                        display: block;
                                    }
                                }
                                button {
                                    border: none;
                                    font-family: $archivo_semibold;
                                    font-size: 16px;
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background-color: $white;
                                    color: var(--colorPrincipal);
                                    height: 45px;
                                    margin-top: 21px;
                                }
                            }
                        }
                    }

                    &.select-servicio {
                        .right-container {
                            min-width: 60px;
                        }
                    }
    
                    &.select-sucursal {
                        .right-container {
                            min-width: 184px;
                        }
                    }
    
                    &.select-profesional {
                        .right-container {
                            min-width: 290px;
                        }
                    }
                    &.select-date {
                        .react-calendar__navigation {
                            padding-bottom: 2px;
                        }
                        .react-calendar__navigation__label span {
                            font-size: 16px;
                        }
                        .react-calendar__month-view__weekdays {
                            border-bottom: 1px solid #E3E0E0;
                        }
                        .react-calendar__month-view__weekdays__weekday {
                            padding-bottom: 6px;
                        }
                        .react-calendar__month-view__weekdays__weekday abbr {
                            font-family: $archivo_regular;
                            font-size: 7px;
                            color: $gris_oscuro;
                        }
                        .react-calendar__tile {
                            border: none !important;
                            padding: 10px 0px !important;
                            color: #666666;
                        }
                        .react-calendar__tile[disabled] {
                            color: #E3E0E0;
                        }
                        .react-calendar__tile abbr {
                            font-family: $archivo_regular !important;
                            font-size: 10px;
                        }
                        .react-calendar__tile--active {
                            background: var(--colorPrincipal);
                            border-radius: 200px;
                            color: $white;
                        }
                    }
                    &.select-time {
                        .horarios-container {
                            padding: 22px 0px 15px;
                        }
                        .horarios-container .horarios-header {
                            font-family: $archivo_semibold;
                            justify-content: space-between;
                            padding: 0px 15px 10px;
                            span.hora {
                                font-size: 12px;
                                color: var(--time-slider-color);
                                padding: 0px 5px;
                            }
                            .delete-btn {
                                color: var(--time-slider-color);
                                cursor: pointer;
                            }
                        }
                        .horarios-container .horario-slider {
                            overflow: hidden;
                            padding: 40px 25px 20px;
                            .pista {
                                height: 3px;
                                background-color: $white;
                                &.pista-1 {
                                    background-color: var(--time-slider-color);
                                }
                            }
                            .punto {
                                width: 0px;
                                cursor: pointer;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: -8px;
                                    left: -8px;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 50%;
                                    background-color: var(--time-slider-color);
                                }
                                span {
                                    position: absolute;
                                    top: -35px;
                                    left: -10px;
                                    padding: 2px 10px;
                                    text-align: center;
                                    border-radius: 5px;
                                    &::after {
                                        content: '';
                                        position: absolute;
                                        bottom: -2px;
                                        left: 10px;
                                        width: 6px;
                                        height: 10px;
                                        background-color: var(--time-slider-color);
                                        -webkit-transform: rotate(45deg);
                                        -ms-transform: rotate(45deg);
                                        transform: rotate(45deg);
                                        z-index: -1;
                                    }
                                    font-family: $archivo_semibold;
                                    font-size: 12px;
                                    color: $white;
                                    background-color: var(--time-slider-color);
                                }
                                &.punto-1 {
                                    span {
                                        left: -38px;
                                        &::after {
                                            left: 37px;
                                        }
                                    }
                                }
                            }
                        }
                        .horarios-container .single-container .horas .horario-item {
                            width: 33%;
                            padding: 10px 15px;
                            border: none;
                        }
                        .horarios-container .single-container .horas .horario-item .check {
                            margin-left: 0px;
                            background-color: $white;
                            border: none;
                        }
                        .horarios-container .single-container .horas .horario-item.active .check::after {
                            top: 4px;
                            left: 4px;
                            width: 8px;
                            height: 8px;
                            background-color: var(--colorPrincipal);
                        }
                        .horarios-container .single-container .horas .horario-item p {
                            font-family: $archivo_semibold;
                            font-size: 14px;
                            margin-left: 10px;
                        }
                        .horarios-container .single-container .horas .horario-item.blocked p::after {
                            bottom: 7px;
                        }
                    }
                }
            }

            .cita-pagada {
                align-items: center;
                display: flex;
                margin-bottom: 12px;
                p {
                    color: $menu_amarillo;
                    font-size: 16px;
                    margin-left: 12px;
                }
            }
            .btn {
                font-family: $archivo_semibold;
                font-size: 16px;
                color: #C3C3C3;
                background-color: #4A4949;
                width: 100%;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                margin-top: 15px;
                &:first-of-type {
                    margin-top: 63px;
                }
            }

            .btn-editar {
                color: $white;
            }

            .btn-reservar {
                background-color: $azul_fuerte_50;
                color: $white;
            }

            .btn-eliminar {
                background-color: var(--colorPrincipal);
                color: $white;
            }

            .errores {
                position: relative;
                width: 100%;
                font-family: $archivo_regular;
                font-size: 12px;
                color: $white;
                padding: 5px 0px 15px;
                .error {
                    display: none;
                    margin: 5px 0px;
                    padding: 3px;
                    background-color: rgba(169, 142, 101, 0.77);
                    &.active {
                        display: block;
                    }
                }
            }

            .comentarios {
                h5 {
                    font-family: $archivo_regular;
                    font-size: 16px;
                    color: var(--colorPrincipal);
                    padding-bottom: 7px;
                    border-bottom: 1px solid var(--colorPrincipal);
                    margin-bottom: 25px;
                }

                h6 {
                    font-family: $archivo_regular;
                    font-size: 14px;
                    color: $azul_flojo;
                }

                &-container {
                    margin-top: 18px;
                }
                &__divisor {
                    margin-top: 18px;
                    height: 1px;
                    background-color: $azul_flojo;
                }

                .comentario {
                    font-family: $archivo_regular;
                    font-size: 16px;
                    color: $white;
                    margin-top: 10px;
                }

                &__comercio__input {
                    input {
                        font-family: $archivo_regular;
                        font-size: 16px;
                        color: $white;
                        background-color: transparent;
                        border: none;
                        padding-left: 0px;
                        margin-top: 30px;
                        width: 100%;
                    }
                }

                &__respuestas {
                    h6 {
                        margin-bottom: 10px;
                    }
                    button {
                        font-family: $archivo_regular;
                        font-size: 14px;
                        background-color: $azul_comentario;
                        color: var(--colorPrincipal);
                        border: none;
                        border-radius: 10px;
                        padding: 12px;
                        margin-bottom: 9px;
                        width: 100%;
                        text-align: left;
                    }
                    
                }

                .btn-send-comentario:hover {
                    background-color: var(--colorPrincipal);
                    color: $white;
                }
            }
        }
        .alerts-body {
            color: $white;
            font-size: 16px;
            height: calc( 100vh - 180px );
            left: 0px;
            margin-top: 17px;
            overflow: auto;
            padding: 0px 20px 0px 20px;
            position: absolute;
            top: 57px;
            width: 100%;
            @include lg(){
                padding: 31px 25px 20px;
                height: calc( 100vh - 155px );
                overflow: auto;
            }
            .alert-item {
                display: flex;
                padding: 11px 0px;
                .alert-icon {
                    width: 38px;
                }
                .alert-content {
                    width: 100%;
                    p {
                        margin-bottom: 5px;
                        span {
                            font-family: $archivo_bold;
                        }
                    }
                    .comments-respuesta {
                        margin-top: 20px;
                        .respuesta-item {
                            padding: 11px 0px;
                            .check-row {
                                align-items: center;
                                cursor: pointer;
                                display: flex;
                                .respuesta-check {
                                    align-items: center;
                                    border-radius: 50%;
                                    display: flex;
                                    height: 17px;
                                    justify-content: center;
                                    min-width: 17px;
                                    width: 17px;
                                    .check-selected {
                                        border-radius: 50%;
                                        height: 7px;
                                        width: 7px;
                                    }
                                }
                                .respuesta-text {
                                    margin-left: 11px;
                                    p {
                                        margin: 0px;
                                    }
                                }
                            }
                            .botonera {
                                padding-left: 27px;
                                .botonera-item {
                                    align-items: center;
                                    cursor: pointer;
                                    display: flex;
                                    font-size: 14px;
                                    height: 40px;
                                    margin-top: 10px;
                                    width: 294px;
                                    svg {
                                        margin-left: 11px;
                                        margin-right: 11px;
                                        position: relative;
                                        top: -2px;
                                    }
                                    &.down {
                                        svg {
                                            position: relative;
                                            top: 1px;
                                        }
                                    }
                                }
                            }
                            .text-area {
                                margin-top: 10px;
                                padding-left: 27px;
                                width: 100%;
                            }
                        }
                        textarea {
                            border: none;
                            font-family: $archivo_semibold;
                            font-size: 14px;
                            line-height: 18px;
                            min-height: 70px;
                            resize: none;
                            width: 100%;
                        }
                        .btn-responder {
                            align-items: center;
                            cursor: pointer;
                            display: flex;
                            height: 40px;
                            justify-content: center;
                            width: 100%;
                            p {
                                margin: 0px;
                            }
                        }
                    }
                }
                &.random {
                    svg {
                        height: 20px;
                        position: relative;
                        top: -1px;
                        width: 25px;
                    }
                }
                &.client-delay {
                    svg {
                        position: relative;
                        top: -1px;
                    }
                }
                &.prof-delay {
                    svg {
                        position: relative;
                        top: -1px;
                    }
                }
                &.pagado {
                    .alert-icon {
                        svg {
                            position: relative;
                            top: 2px;
                        }
                    }
                    .ok-pagado {
                        margin-left: 5px;
                        svg {
                            position: relative;
                            top: 2px;
                        }
                    }
                }
                &.comments {
                    svg {
                        position: relative;
                        top: 3px;
                    }
                }
            }
        }
    }
    .react-calendar {
        padding: 0px 5px 15px;
    }
}